.app{
  background-color: #F9D148;
  min-height: 100vh;
  width: fit-content;
  min-width: 100vw;
}
/* components */
.header{
  background-color: #656565;
  color: white;
}
.footer{
  background-color: #656565;
  color: white;
}
.footer-info{
  display: flex;
  flex-direction: row;
}
.login{
  min-height: 100vh;
}
.admin {
  min-height: 100vh;
}
.auth {
  min-height:100vh
}
/* forms */
.login-form{
  width: 200px;
  margin: 20px;
}
.search-form{
  width: 200px;
  margin: 20px;
}
.user-form{
  width: 400px;
  /* padding: 20px; */
  margin: 20px;
}
.new-record-form {
  /* background-color: grey;
  border: 1px solid #95999c;
  color: #FFF; */
  padding: 4px 10px;
  margin-bottom: 20px;
  max-width: 95vw;
  border-bottom: 1px solid #dee2e6;
}

/* box */
.nav-bar{
  width: 100%;
  background-color: #656565;
}
.header-admin-box {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.header-admin-box b{
  color: #F9D148;
  margin-right: 10px;
}
.user-single{
  width: 400px;
  margin: 20px;
}
.user-list-scroller{
  margin: 20px;
  padding: 20px;
  width: fit-content;
  max-width: 95vw;
  overflow: scroll;
  color: white;
  background-color: #6565654b;
}
.user-list{
  width: fit-content;
  display: flex;
  flex-direction: row;
}
.user-list-single{
  width: 200px;
  margin: 4px;
  padding: 16px;
}
.record-list{
  padding-top: 20px;
  width: 100%;
  max-width: calc(100vw - 60px);
}
.record-list-table-wrapper{
  overflow: scroll;
  max-width: 100%;
}
.record-list-column{
  min-width: 130px;
}
.record-list-column button{
  margin: 5px;
}
.record-search-box {
  width: 200px;
  margin: 20px;
}
.reminder-card-list {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.reminder-card {
  width: 18rem;
  margin: 10px;
}
.auth-box {
  padding: 20px;
}
.flex-column-center{
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-center{
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wxlogin-box {
  padding: 20px 0;
  height: 100px;
  justify-content: space-around;
  color:rgb(71, 71, 71);
}

/* input */
.edit-input{
  width: 100%;
  height: 30px;
}
.edit-select{
  width: 100%;
  height: 30px;
}

/* image */
.logo{
  width: 40px;
  height: 40px;
  background-image: url('./Images/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 10px 8px 0;
}

/* button */
.nav-btn-group{
  display: flex;
  flex-direction: row;
}
.nav-btn{
  height: 40px;
  width: 120px;
  color: #F9D148;
  background-color: rgba(101, 101, 101, 0);
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}
.nav-btn:hover{
  background-color: rgb(71, 71, 71);
}






.submit-btn{
  width: 160px;
  height: 30px;
  margin: 10px;
  border: none;
  background-color: #F9D148;
  color: grey;
}
.input-single{
  width: 200px;
  height: 20px;
  margin: 10px;
}

.record-single{
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}
.record-column{
  width: 160px;
  height: 60px;
  background-color: grey;
  color: white;
  border: 0.5px solid #F9D148;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-box{
  display: flex;
  flex-direction: column;
  width: 400px;
}

.admin-btn {
  margin: 10px 0;
}

.article-list:hover{
  cursor: pointer;
  background-color: rgba(249, 208, 72, 0.2);
}

/* .find_user_navs.nav-link{
  padding: 2px;
  margin: 6px 14px;
}
.find_user_navs.nav-link.active{
  background-color: #F9D148 !important;
  color: #393939 !important;
  font-weight: 500;
  border-radius: 0%;
  border-bottom: 2px solid #393939;
}
.find_user_navs.nav-link:hover{
  color: #393939 !important;
  border-radius: 0%;
  border-bottom: 2px solid #393939;
} */

.find_user_navs.nav-link.active{
  background-color: #F9D148 !important;
  color: #393939 !important;
  font-weight: 500;
  border-bottom: 1px solid #F9D148 !important;
}

.admin_navs.nav-link.active{
  background-color: #393939 !important;
  color: #F9D148 !important;
  font-weight: 500;
}

.admin_navs.nav-link:hover{
  background-color: #464646 !important;
}

.data_navs.nav-link {
  padding: 2px;
  margin: 6px 14px;
}
.data_navs.nav-link.active{
  background-color: #393939 !important;
  border-radius: 0%;
  border-bottom: 1px solid #F9D148;
  color: #F9D148 !important;
  font-weight: 500;
}
.data_navs.nav-link:hover{
  /* background-color: #464646 !important; */
  border-radius: 0%;
  border-bottom: 1px solid #F9D148;
}

.user-info-field {
  padding-left: 15px;
}
